import { FormBuilder } from '@ginkgo-bioworks/react-json-schema-form-builder';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';
import { Accordion, Dimmer, Icon, Loader } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { transformErrors } from '../../Shared/FormHelpers/helpers/utils';

import { isEqual, trim } from 'lodash';
import { Button, Modal } from 'semantic-ui-react';
import { selectTab } from '../../../actions/adminActions';
import {
  addQuestion,
  fetchQuestion,
  updateQuestion,
} from '../../../actions/surveyActions';
import AmsAlert from '../../../utils/AmsAlert';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsModal from '../../../utils/AmsModal';
import EASArrayFieldTemplate from '../../Shared/SurveyForms/EASArrayFieldTemplate';
import EASForm from '../../Shared/SurveyForms/EASForm';
import { customFields } from '../../Shared/SurveyForms/customFields';
import { customFormInputs } from '../../Shared/SurveyForms/customFormInputs';
import { customWidgets } from '../../Shared/SurveyForms/customWidgets';
import './style.css';
import {
  AdditionalCitation,
  buildQuestionDataUsingSchema,
  FindingsOutsideTheProtocolForm,
  PreReviewTemplates,
  QuestionTemplates,
  SPTQ1,
  FiscalYears,
  BulkQuestionTemplates,
  TemplatesRequireCitations,
  modifySchema,
} from './utils';

export default function SurveyQuestionsPage(props) {
  const dispatch = useDispatch();
  const [questionTemplate, setQuestionTemplate] = useState();
  const [fiscalYear, setFiscalYear] = useState();
  const [orginalSchema, setOrginalSchema] = useState('{}');
  const [schema, setSchema] = useState('{}');
  const [uischema, setUiSchema] = useState('{}');
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState([1]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [contentAreas, setContentAreas] = useState(null);
  const [questionInput, setQuestionInput] = useState(null);
  const [refreshedData, setRefreshedData] = useState(null);
  const [showConflictWarning, setShowConflictWarning] = useState(0);
  const [activePanel, setActivePanel] = useState(false);

  //list of inputs we want to omit from the input type dropdown
  var filteredInputTypes = [
    'Guide',
    'Citations',
    'Performance Area',
    'Content Area',
    'Program Type',
    'Program Option',
    'Finding Category',
    'Password',
    'Performance Measure',
    'Review Type',
    'Tags',
    'Script',
    'Responsive Script',
    'Guidance',
    'Reference Notes',
    'Order',
    'TQ Answer',
    'Accordion Title',
  ];

  useEffect(() => updateTemplate(), [questionTemplate, fiscalYear]);

  useEffect(() => {
    if (props.router.params.id) {
      setLoading(true);
      setId(props.router.params.id);
      let fetchRequest = { questionId: props.router.params.id };
      fetchQuestionInfo(fetchRequest);
      let formContainer = document.getElementById('eas-form-builder');
      if (formContainer) {
        formContainer.classList.add('edit');
      }
    }
  }, []);

  useEffect(() => {
    //on every render, we want to target each indicator container (select dropdown icon) and
    //add a click listener to remove the input types from the dropdown so as soon as the dropdown is clicked
    //the options are removed from the dom
    const handleClick = event => {
      if ($('[id$="-listbox"]').length > 0) {
        $('[id$="-listbox"]')
          .children()
          .children()
          .each((e, element) => {
            var elementText = $(element).text();
            if (filteredInputTypes.includes(elementText)) {
              $(element).remove();
            }
          });
      }
    };
    $('[class*="indicatorContainer"]').each(function() {
      if (
        $(this)
          .parents('.card-entry')
          .find('h5')
          .text() === 'Input Type '
      ) {
        // add click event listener to each element
        setTimeout(function() {
          this.addEventListener('click', handleClick);
        }, 500);
      }
    });

    return () => {
      $('[class*="indicatorContainer"]').each(function() {
        if (
          $(this)
            .parents('.card-entry')
            .find('h5')
            .text() === 'Input Type '
        ) {
          this.removeEventListener('click', handleClick);
        }
      });
    };
  });
  // Accordion
  const handleAccordionClick = index => {
    setActiveIndexes(prevIndexes => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter(item => item !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };
  const checkQuestionRequirements = formSchema => {
    function hasDuplicates(a) {
      if (a) {
        const noDups = new Set(a);
        return a.length !== noDups.size;
      }
      return false;
    }

    const findEnum = flattenObjectExcludeKey(formSchema, 'enum');
    let validEnums = true;
    let invalidEnum = '';
    Object.keys(findEnum).every(e => {
      if (findEnum[e].hasOwnProperty('enum')) {
        const duplicatesFound = hasDuplicates(findEnum[e].enum);
        if (duplicatesFound === true) {
          validEnums = false;
          const invalidProp = e.split('.');
          invalidEnum = `${
            invalidProp[invalidProp.length - 2] === undefined
              ? ''
              : invalidProp[invalidProp.length - 2] + '.'
          }${invalidProp[invalidProp.length - 1]}`;
          return false;
        }
      }
      return true;
    });

    if (validEnums === false) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        `Duplicate values found in  "${invalidEnum}". Please make sure that there are no duplicate elements in widgets such as dropdown menus, checkbox lists, and other similar components.`
      );
      return false;
    }
    const questionDetails =
      formSchema.properties[Object.keys(formSchema.properties)[0]].properties;
    if (questionDetails.fiscal_year && !questionDetails.fiscal_year?.value) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Fiscal Year before creating a question from a template.'
      );
      return false;
    }
    if (!questionDetails.review_type.value) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Review Type before creating a question from a template.'
      );
      return false;
    }
    if (questionDetails.order && !questionDetails.order.value) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select an Order before creating a question from a template.'
      );
      return false;
    }

    if (
      !PreReviewTemplates.includes(template) &&
      template != AdditionalCitation &&
      template != FindingsOutsideTheProtocolForm &&
      questionDetails.content_area &&
      !questionDetails.content_area.value
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Content Area before creating a question from a template.'
      );
      return false;
    }
    if (
      !PreReviewTemplates.includes(template) &&
      questionDetails.performance_measure &&
      !questionDetails.performance_measure.value
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Performance Measure before creating a question from a template.'
      );
      return false;
    }
    if (
      !PreReviewTemplates.includes(template) &&
      template != AdditionalCitation &&
      template != FindingsOutsideTheProtocolForm &&
      questionDetails.performance_area &&
      !questionDetails.performance_area.value
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Performance Area before creating a question from a template.'
      );
      return false;
    }
    if (
      questionDetails.accordion_title &&
      !questionDetails.accordion_title.value
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select an Accordion Title before creating a question from a template.'
      );
      return false;
    }

    if (
      questionDetails.evidence_source &&
      (!questionDetails.evidence_source.value ||
        questionDetails.evidence_source.value.length === 0)
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Guide before creating a question from a template.'
      );
      return false;
    }
    //validation for both and neither
    if (questionDetails.program_option && questionDetails.program_type) {
      if (
        (!questionDetails.program_type.value &&
          questionDetails.program_option.value &&
          questionDetails.program_option.value.length !== 0) ||
        (((questionDetails.program_option.value &&
          questionDetails.program_option.value.length === 0) ||
          !questionDetails.program_option.value) &&
          questionDetails.program_type.value)
      ) {
        setLoading(false);
        setShowWarning(true);
        setShowWarningMessage(
          'Please either select both program type and program option or dont select any of those'
        );
        return false;
      }
    }

    if (
      SPTQ1 === template &&
      (!questionDetails.finding_category.value ||
        questionDetails.finding_category.value.length === 0)
    ) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please select a Finding Category before creating a question from a template.'
      );
      return false;
    }
    if (questionDetails.order && !questionDetails.order.value) {
      setLoading(false);
      setShowWarning(true);
      setShowWarningMessage(
        'Please enter an order before creating a question from a template.'
      );
      return false;
    }

    return true;
  };

  const flattenObjectExcludeKey = (obj, excludeKey = null) => {
    const result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (let i = 0; i < cur.length; i++) {
          recurse(cur[i], `${prop}[${i}]`);
        }
        if (cur.length === 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (const p in cur) {
          if (excludeKey && p === excludeKey) {
            result[prop] = cur;
            isEmpty = false;
            break;
          }
          isEmpty = false;
          recurse(cur[p], prop ? `${prop}.${p}` : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }

    recurse(obj, '');

    return result;
  };

  const checkQuestion = () => {
    if (id === null) {
      let input = buildQuestionDataUsingSchema(
        null,
        null,
        schema,
        uischema,
        0,
        template,
        'ui'
      );

      if (!checkQuestionRequirements(input.formSchema)) {
        return null;
      }
      setShowConfirmation(true);
      setQuestionInput(input);
    } else {
      let input = buildQuestionDataUsingSchema(
        id,
        'modify',
        schema,
        uischema,
        editData.questionVersion + 1,
        editData.attributesObj.template,
        editData.source ?? 'ui'
      );

      if (!checkQuestionRequirements(input.formSchema)) {
        return null;
      }
      setShowConfirmation(true);
      setQuestionInput(input);
    }
    setLoading(false);
  };

  const saveQuestion = () => {
    setLoading(true);
    if (id === null) {
      dispatch(addQuestion(questionInput))
        .then(e => {
          setLoading(false);
          if (e?.reviewIds?.length > 0) {
            setRefreshedData(e);
            setShowConflictWarning(1);
          } else {
            browserHistory.push(
              '/admin/surveyquestions/' + e?.surveyQuestion?._id + '/edit'
            );
            refreshData(e?.surveyQuestion);
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      dispatch(updateQuestion(questionInput))
        .then(e => {
          setLoading(false);
          if (e?.reviewIds?.length > 0) {
            setRefreshedData(e);
            setShowConflictWarning(2);
          } else {
            refreshData(e?.surveyQuestion);
            browserHistory.push('/admin/surveyquestions');
          }
        })
        .catch(e => {
          alert('Unable to update question. Exception: ' + e);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    dispatch(
      selectTab({
        key: 8,
        pageTitle: props.router.params.id
          ? 'Edit Survey Question'
          : 'Create Survey Question',
      })
    );
  }, []);

  const renderWarningMessage = () => {
    return (
      <AmsAlert
        show={showWarning}
        title=""
        text={showWarningMessage}
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'OK'}
        showConfirm
        onConfirm={() => {
          setShowWarning(false);
        }}
      />
    );
  };

  const renderConflictWarning = () => {
    return (
      <AmsModal
        open={showConflictWarning != 0}
        className="ams-semantic-modal-fix"
        closeIcon={false}
        centered={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Your Changes Have Been Saved</Modal.Header>
        <Modal.Content>
          <p>{refreshedData?.message}</p>
          <Accordion>
            <Accordion.Title
              active={activePanel === true}
              index={0}
              onClick={e => setActivePanel(!activePanel)}
            >
              <Icon name="dropdown" />
              <span style={{ color: '#337ab7' }}>View Review IDs</span>
            </Accordion.Title>
            <Accordion.Content
              active={activePanel === true}
              style={{ padding: '0px 20px' }}
            >
              {refreshedData?.reviewIds.join(', ')}
            </Accordion.Content>
          </Accordion>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              if (showConflictWarning == 1) {
                browserHistory.push(
                  '/admin/surveyquestions/' +
                    refreshedData?.surveyQuestion?._id +
                    '/edit'
                );
                refreshData(refreshedData?.surveyQuestion);
              } else if (showConflictWarning == 2) {
                refreshData(refreshedData?.surveyQuestion);
                browserHistory.push('/admin/surveyquestions');
              }
              setShowConflictWarning(0);
            }}
            tabIndex={0}
            content="Close"
          />
        </Modal.Actions>
      </AmsModal>
    );
  };

  const updateTemplate = () => {
    if (
      typeof questionTemplate === 'string' &&
      questionTemplate !== '0' &&
      typeof fiscalYear === 'string' &&
      fiscalYear !== '0'
    ) {
      let schema = require(`./Templates/${fiscalYear}/${questionTemplate}.json`);
      let uiSchema = require(`./Templates/${fiscalYear}/${questionTemplate}_ui.json`);

      // change key to a random key
      var newkey = uuidv4();
      var oldkey = Object.keys(schema.properties)[0];

      schema.properties[newkey] = schema.properties[oldkey];
      delete schema.properties[oldkey];
      // set new values for ui schema
      uiSchema[newkey] = uiSchema[oldkey];
      delete uiSchema[oldkey];
      uiSchema['ui:order'] = [newkey];

      setSchema(JSON.stringify(schema));
      setUiSchema(JSON.stringify(uiSchema));
      setTemplate(questionTemplate);
    } else {
      setTemplate(null);
      setSchema('{}');
      setUiSchema('{}');
    }
  };

  function modifyObject(obj) {
    for (var key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        modifyObject(obj[key]); // recursively call function for nested objects

        if (
          JSON.stringify(obj[key]) ===
          '{"ui:options":{"orderable":false,"removable":true}}'
        ) {
          obj[key]['items'] = { 'ui:field': 'attachmentField' }; // add key-value pair
        }
      }
    }
    return obj;
  }

  const adjustSchema = (json, oldSchema) => {
    const obj = JSON.parse(json);
    const oldObj = JSON.parse(oldSchema);
    const propertyKey = Object.keys(obj.properties).find(
      key => obj.properties[key].properties.review_type
    );
    if (obj.properties[propertyKey].properties.fiscal_year) {
      obj.properties[propertyKey].properties.review_type.fiscalYear =
        obj.properties[propertyKey].properties.fiscal_year.value;
    }
    if (obj.properties[propertyKey].properties.evidence_source) {
      obj.properties[propertyKey].properties.evidence_source.reviewType =
        obj.properties[propertyKey].properties.review_type.value;
    }

    if (obj.properties[propertyKey].properties.content_area) {
      obj.properties[propertyKey].properties.content_area.reviewType =
        obj.properties[propertyKey].properties.review_type.value;
      obj.properties[propertyKey].properties.content_area.fiscalYear =
        obj.properties[propertyKey].properties.fiscal_year.value;
    }

    if (obj.properties[propertyKey].properties.performance_area) {
      obj.properties[propertyKey].properties.performance_area.reviewType =
        obj.properties[propertyKey].properties.review_type.value;
      obj.properties[propertyKey].properties.performance_area.fiscalYear =
        obj.properties[propertyKey].properties.fiscal_year.value;
      obj.properties[propertyKey].properties.performance_area.contentArea =
        obj.properties[propertyKey].properties.content_area.value;
    }

    if (obj.properties[propertyKey].properties.performance_measure) {
      obj.properties[propertyKey].properties.performance_measure.reviewType =
        obj.properties[propertyKey].properties.review_type.value;
      obj.properties[propertyKey].properties.performance_measure.fiscalYear =
        obj.properties[propertyKey].properties.fiscal_year.value;
      obj.properties[propertyKey].properties.performance_measure.contentArea =
        obj.properties[propertyKey].properties.content_area.value;
      obj.properties[
        propertyKey
      ].properties.performance_measure.performanceArea =
        obj.properties[propertyKey].properties.performance_area.value;
    }

    if (
      oldObj.properties[propertyKey].properties.fiscal_year.value != null &&
      obj.properties[propertyKey].properties.fiscal_year.value !=
        oldObj.properties[propertyKey].properties.fiscal_year.value
    ) {
      obj.properties[propertyKey].properties.review_type.value = null;
      obj.properties[propertyKey].properties.content_area.value = null;
      obj.properties[propertyKey].properties.performance_area.value = null;
      obj.properties[propertyKey].properties.performance_measure.value = null;
      obj.properties[propertyKey].properties.content_area.reviewType = null;
      obj.properties[propertyKey].properties.performance_area.reviewType = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.reviewType = null;
      obj.properties[
        propertyKey
      ].properties.performance_area.contentArea = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.contentArea = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.performanceArea = null;
    }

    if (
      oldObj.properties[propertyKey].properties.review_type.value != null &&
      obj.properties[propertyKey].properties.review_type.value !=
        oldObj.properties[propertyKey].properties.review_type.value
    ) {
      obj.properties[propertyKey].properties.content_area.value = null;
      obj.properties[propertyKey].properties.performance_area.value = null;
      obj.properties[
        propertyKey
      ].properties.performance_area.contentArea = null;
      obj.properties[propertyKey].properties.performance_measure.value = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.contentArea = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.performanceArea = null;
    }
    if (
      oldObj.properties[propertyKey].properties.content_area.value != null &&
      obj.properties[propertyKey].properties.content_area.value !=
        oldObj.properties[propertyKey].properties.content_area.value
    ) {
      obj.properties[propertyKey].properties.performance_area.value = null;
      obj.properties[propertyKey].properties.performance_measure.value = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.contentArea = null;
      obj.properties[
        propertyKey
      ].properties.performance_measure.performanceArea = null;
    }
    if (
      oldObj.properties[propertyKey].properties.performance_area.value !=
        null &&
      obj.properties[propertyKey].properties.performance_area.value !=
        oldObj.properties[propertyKey].properties.performance_area.value
    ) {
      obj.properties[propertyKey].properties.performance_measure.value = null;
    }

    ['citations', 'guidance', 'reference_notes'].forEach(prop => {
      if (obj.properties[propertyKey].properties[prop]) {
        obj.properties[propertyKey].properties.tq_answer[prop] =
          obj.properties[propertyKey].properties[prop].value;
      }
    });
    if (
      TemplatesRequireCitations.includes(trim(template)) &&
      obj.properties[propertyKey].properties.fiscal_year.value > 2024
    ) {
      modifySchema(obj);
    }
    return JSON.stringify(obj, null, 2);
  };

  const refreshData = data => {
    setOrginalSchema(JSON.stringify(data.formSchema));
    setSchema(JSON.stringify(data.formSchema));
    setUiSchema(JSON.stringify(data.uiSchema));
    setEditData(data);
    setId(data._id);
    setTemplate(data?.attributesObj?.template);
  };

  const fetchQuestionInfo = request => {
    dispatch(fetchQuestion(request))
      .then(data => {
        setLoading(false);
        refreshData(data);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const fetchHistory = item => {
    setLoading(true);
    let fetchRequest = {
      questionId: props.router.params.id,
      version: item.version,
      versionQuestionId: item.versionQuestionId,
    };
    fetchQuestionInfo(fetchRequest);
  };

  const renderFiscalYear = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <label style={{ paddingRight: '10px' }}>Fiscal Year:</label>
        <span>{editData.fiscalYear}</span>
      </div>
    );
  };

  const TemplateSelectionForm = () => {
    return (
      <div>
        <label>Select a Fiscal Year:</label>
        <select
          id="question-template"
          className="form-control"
          onChange={e => {
            setQuestionTemplate(null);
            setSchema('{}');
            setUiSchema('{}');
            setFiscalYear(e.target.value);
          }}
          value={fiscalYear}
        >
          <option value={0}>-- Select Year --</option>
          {FiscalYears.map(x => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </select>
        <label>Select Question Template:</label>
        <select
          id="question-template"
          className="form-control"
          disabled={!fiscalYear}
          onChange={e => setQuestionTemplate(e.target.value)}
          value={questionTemplate}
        >
          <option value={0}>-- Select Template --</option>
          {BulkQuestionTemplates(fiscalYear).map(x => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div id="survey-questions-page">
      <AmsAlert
        show={showConfirmation}
        title="Save Question"
        text={`Are you sure you want continue?`}
        type={'warning'}
        confirmButtonColor={'#DD6B55'}
        confirmButtonText={'Yes'}
        cancelButtonText={'No'}
        showConfirm
        showCancelButton
        onCancel={() => {
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          setShowConfirmation(false);
          saveQuestion();
        }}
      />
      {renderConflictWarning()}
      <div className="survey-question-section">
        <React.Fragment>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndexes.includes(1)}
              index={1}
              className="survey-question-header"
              onClick={() => handleAccordionClick(1)}
            >
              <h3>
                Content Editor
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndexes.includes(1) ? 'minus' : 'plus'}
                />
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeIndexes.includes(1)}>
              <div className="row">
                <div className="col-md-6">
                  {loading && (
                    <Dimmer active inverted>
                      <Loader content="Loading..." />
                    </Dimmer>
                  )}
                  <div className="widget">
                    <div className="widget-header">
                      <h4>
                        Question Form
                        {editData.isActive || !id ? (
                          <></>
                        ) : (
                          <span
                            className="badge"
                            style={{
                              background: '#f25656',
                              color: '#fff',
                              float: 'right',
                            }}
                          >
                            Inactive
                          </span>
                        )}
                        {!id ? (
                          <></>
                        ) : (
                          <span
                            className="badge"
                            style={{
                              background: '#12afcb',
                              color: '#fff',
                              float: 'right',
                              marginRight: '5px',
                            }}
                          >
                            Version {editData.questionVersion}
                          </span>
                        )}
                      </h4>
                    </div>
                    {renderWarningMessage()}
                    <div className="widget-content-area">
                      {id ? (
                        <>{renderFiscalYear()}</>
                      ) : (
                        <TemplateSelectionForm />
                      )}
                      <div id="eas-form-builder">
                        <FormBuilder
                          schema={schema}
                          mods={{
                            customFormInputs,
                          }}
                          uischema={uischema}
                          onChange={(newSchema, newUiSchema) => {
                            setSchema(adjustSchema(newSchema, schema));
                            newUiSchema = JSON.stringify(
                              modifyObject(JSON.parse(newUiSchema))
                            );
                            setUiSchema(newUiSchema);
                          }}
                        />
                      </div>
                      <div>
                        {editData.isActive || !id ? (
                          <div className="btn-list">
                            <button
                              disabled={isEqual(schema, orginalSchema) && id}
                              onClick={() => checkQuestion()}
                              className={
                                template || id ? 'btn btn-primary' : 'hide'
                              }
                            >
                              Save Question
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="widget">
                    <div className="widget-header">
                      <h4>Preview Question</h4>
                    </div>
                    <div className="widget-content-area">
                      <div id="eas-form">
                        <EASForm
                          schema={JSON.parse(schema)}
                          uiSchema={JSON.parse(uischema)}
                          ArrayFieldTemplate={EASArrayFieldTemplate}
                          fields={customFields}
                          widgets={customWidgets}
                          children={true}
                          transformErrors={transformErrors}
                          onAutoSave={(autosaveData, validated) => {
                            console.log('Data to save:', autosaveData);
                            console.log('Valdated: ', validated);
                          }}
                          formContext={{
                            reviewId: id,
                            templateType: template,
                            fiscalYear: editData?.fiscalYear,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Content>
          </Accordion>
        </React.Fragment>
      </div>
      {editData.surveyQuestionVersionHistory &&
      editData.surveyQuestionVersionHistory.length > 0 ? (
        <div className="survey-question-section">
          <React.Fragment>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndexes.includes(2)}
                index={2}
                className="survey-question-header"
                onClick={() => handleAccordionClick(2)}
              >
                <h3>
                  Version History
                  <Icon
                    style={{ float: 'right' }}
                    name={activeIndexes.includes(2) ? 'minus' : 'plus'}
                  />
                </h3>
              </Accordion.Title>
              <Accordion.Content active={activeIndexes.includes(2)}>
                <table className="table table-border">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Edited By</th>
                      <th>Edited On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editData.surveyQuestionVersionHistory?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <a
                                onClick={event => {
                                  event.preventDefault();
                                  fetchHistory(item);
                                }}
                                href="#"
                              >
                                {item.version - 1}
                              </a>
                            </td>
                            <td>{item.editedByFullName}</td>
                            <td>
                              {AmsDateFormatters.formatDateTime(item.editTime)}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Accordion.Content>
            </Accordion>
          </React.Fragment>
        </div>
      ) : (
        <></>
      )}
      <div className="survey-question-section">
        <React.Fragment>
          <Accordion fluid styled>
            <Accordion.Title
              active={activeIndexes.includes(3)}
              index={3}
              className="survey-question-header"
              onClick={() => handleAccordionClick(3)}
            >
              <h3>
                Json
                <Icon
                  style={{ float: 'right' }}
                  name={activeIndexes.includes(3) ? 'minus' : 'plus'}
                />
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeIndexes.includes(3)}>
              <div className="row">
                <div className="col-md-6">
                  <h1>Question - Data schema</h1>
                  <JSONInput
                    placeholder={
                      schema
                        ? (() => {
                            try {
                              return JSON.parse(schema);
                            } catch (err) {
                              console.error(err);
                              return {};
                            }
                          })()
                        : {}
                    }
                    locale={locale}
                    width={'100%'}
                    viewOnly
                  />
                </div>
                <div className="col-md-6">
                  <h1>Question - UI data Schema</h1>
                  <JSONInput
                    placeholder={
                      uischema
                        ? (() => {
                            try {
                              return JSON.parse(uischema);
                            } catch (err) {
                              console.error(err);
                              return {};
                            }
                          })()
                        : {}
                    }
                    locale={locale}
                    width={'100%'}
                    viewOnly
                  />
                </div>
              </div>
            </Accordion.Content>
          </Accordion>
        </React.Fragment>
      </div>
    </div>
  );
}
