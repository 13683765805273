// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

// Me
export const FETCH_ME = 'FETCH_ME';

// Help

export const CATEGORY_ADDED = 'CATEGORY_ADDED';
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_DELETED = 'CATEGORY_DELETED';
export const CATEGORY_FETCHED = 'CATEGORY_FETCHED';

export const TAGS_FETCHED = 'TAGS_FETCHED';
export const TAG_DELETED = 'TAG_DELETED';

export const POST_FETCHED = 'POST_FETCHED';
export const POST_DELETED = 'POST_DELETED';
export const POST_ADDED = 'POST_ADDED';

// Carousel
export const CAROUSEL_ADDED = 'CAROUSEL_ADDED';
export const CAROUSEL_FETCHED = 'CAROUSEL_FETCHED';
export const CAROUSEL_UPDATED = 'CAROUSEL_UPDATED';
export const CAROUSEL_DELETED = 'CAROUSEL_DELETED';
export const CAROUSEL_IMAGE_ADDED = 'CAROUSEL_IMAGE_ADDED';

// Task
export const TASK_ADDED = 'TASK_ADDED';
export const TASK_FETCHED = 'TASK_FETCHED';
export const TASK_UPDATED = 'TASK_UPDATED';
export const TASK_DELETED = 'TASK_DELETED';
export const TASK_DETAIL_FETCHED = 'TASK_DETAIL_FETCHED';
export const TASKS_FETCHED = 'TASKS_FETCHED';
export const DELEGATED_TASKS_FETCHED = 'DELEGATED_TASKS_FETCHED';
export const TASK_REPORT_FINDINGS_DETAIL_FETCHED =
  'TASK_REPORT_FINDINGS_DETAIL_FETCHED';
export const TASK_REPORT_FINDINGS_FEEDBACK_PROVIDED =
  'TASK_REPORT_FINDINGS_FEEDBACK_PROVIDED';

// Resources
export const RESOURCE_ADDED = 'RESOURCE_ADDED';
export const RESOURCE_FETCHED = 'RESOURCE_FETCHED';
export const RESOURCE_UPDATED = 'RESOURCE_UPDATED';
export const RESOURCE_DELETED = 'RESOURCE_DELETED';

// Review
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_REVIEW_DETAIL = 'SET_REVIEW_DETAIL';
export const SET_REVIEW_ERROR = 'SET_REVIEW_ERROR';
export const REVIEW_ADDED = 'REVIEW_ADDED';
export const REVIEW_SELECTED = 'REVIEW_SELECTED';
export const REVIEWS_FETCHED = 'REVIEWS_FETCHED';
export const REVIEW_FINALIZED = 'REVIEW_FINALIZED';
export const REVIEW_DRAFT_REPORT = 'REVIEW_DRAFT_REPORT';
export const MANIFEST_FETCHED = 'MANIFEST_FETCHED';
export const MANIFEST_FILE_RECREATED = 'MANIFEST_FILE_RECREATED';
export const REVIEW_SURVEY_SUBMISSIONS_FETCHED =
  'REVIEW_SURVEY_SUBMISSIONS_FETCHED';
export const REVIEW_SURVEY_SUBMITTERS_FETCHED =
  'REVIEW_SURVEY_SUBMITTERS_FETCHED';
export const REVIEW_FIDINGS_DETAILS = 'REVIEW_FIDINGS_DETAILS';
export const REVIEW_FOLLOWUP_RECOMMENDATIONS =
  'REVIEW_FOLLOWUP_RECOMMENDATIONS';
export const REVIEW_EMAIL_NOTIFY_MPS = 'REVIEW_EMAIL_NOTIFY_MPS';
export const REVIEW_EMAIL_NOTIFY_RL = 'REVIEW_EMAIL_NOTIFY_RL';
export const REVIEW_EMAIL_NOTIFY_QC = 'REVIEW_EMAIL_NOTIFY_QC';
export const REVIEW_EMAIL_NOTIFY_FOM = 'REVIEW_EMAIL_NOTIFY_FOM';
export const FETCH_REGIONAL_REVIEWS = 'FETCH_REGIONAL_REVIEWS';
export const REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST =
  'REVIEW_RPM_FEEDBACK_PROGRAM_SPECIALIST';
export const REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST =
  'REVIEW_RPM_FEEDBACK_FISCAL_SPECIALIST';
export const REVIEW_RPM_FEEDBACK = 'REVIEW_RPM_FEEDBACK';
export const UNSCHEDULED_REVIEWERS_FETCHED = 'UNSCHEDULED_REVIEWERS_FETCHED';
export const UNSCHEDULED_REVIEWERS_CLEARED = 'UNSCHEDULED_REVIEWERS_CLEARED';
export const REVIEW_INFORMATION_FETCHED = 'REVIEW_INFORMATION_FETCHED';
export const REVIEW_DOCUMENTS_FETCHED = 'REVIEW_DOCUMENTS_FETCHED';
export const REVIEW_DOCUMENT_ADDED = 'REVIEW_DOCUMENT_ADDED';
export const REVIEW_EVIDENCE_ADDED = 'REVIEW_EVIDENCE_ADDED';
export const REVIEW_EVIDENCE_FETCHED = 'REVIEW_EVIDENCE_FETCHED';
export const REVIEW_EVIDENCE_DOWNLOADED = 'REVIEW_EVIDENCE_DOWNLOADED';
export const REVIEW_FOLLOWUP = 'REVIEW_FOLLOWUP';
export const REVIEW_FINALIZE_FUR = 'REVIEW_FINALIZE_FUR';
export const UPDATE_REVIEW_STATUS = 'UPDATE_REVIEW_STATUS';
export const REVIEW_CREATE_FOLLOWUP_REVIEW = 'REVIEW_CREATE_FOLLOWUP_REVIEW';
export const REVIEW_SET_RESPONSE_OBJ = 'REVIEW_SET_RESPONSE_OBJ';
export const REVIEW_SUBMISSIONS_FETCHED = 'REVIEW_SUBMISSIONS_FETCHED';
export const SURVEY_SUBMISSIONS_FETCHED = 'SURVEY_SUBMISSIONS_FETCHED';
export const REVIEW_FU_SUBMISSIONS_FETCHED = 'REVIEW_FU_SUBMISSIONS_FETCHED';
export const REVIEW_SUBMISSIONS_CLEARED = 'REVIEW_SUBMISSIONS_CLEARED';
export const REVIEW_GUIDES_FETCHED = 'REVIEW_GUIDES_FETCHED';
export const SURVEY_GUIDES_FETCHED = 'SURVEY_GUIDES_FETCHED';
export const SURVEY_CITATIONS_FETCHED = 'SURVEY_CITATIONS_FETCHED';
export const SURRVEY_GUIDE_DETAILS_FETCHED = 'SURRVEY_GUIDE_DETAILS_FETCHED';
export const REVIEW_PERFORMANCE_MEASURES_FETCHED =
  'REVIEW_PERFORMANCE_MEASURES_FETCHED';
export const REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED =
  'REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED';
export const REVIEW_OCI_SUMMARY_FETCHED = 'REVIEW_OCI_SUMMARY_FETCHED';
export const CITATIONS_FETCHED = 'CITATIONS_FETCHED';
export const CITATIONS_FILTERED = 'CITATIONS_FILTERED';
export const CITATIONS_FILTER_CLEARED = 'CITATIONS_FILTER_CLEARED';
export const REVIEW_FOLLOWUP_SUBMISSIONS = 'REVIEW_FOLLOWUP_SUBMISSIONS';
export const REVIEWER_POSITION_CLEARED = 'REVIEWER_POSITION_CLEARED';
export const REVIEWER_POSITION_ADD = 'REVIEWER_POSITION_ADD';
export const REVIEWER_POSITION_REVIEWER_ADD = 'REVIEWER_POSITION_REVIEWER_ADD';
export const REVIEWER_POSITION_SELECTED = 'REVIEWER_POSITION_SELECTED';
export const REVIEWER_POSITION_FETCHED = 'REVIEWER_POSITION_SELECTED';
export const REVIEWER_POSITION_REVIEWER_UPDATE =
  'REVIEWER_POSITION_REVIEWER_UPDATE';
export const REVIEW_FOLLOWUPS_FETCHED = 'REVIEW_FOLLOWUPS_FETCHED';
export const EVIDENCE_BINDER_FETCHED = 'EVIDENCE_BINDER_FETCHED';
export const REVIEWER_TOTAL_INCREMENT = 'REVIEWER_TOTAL_INCREMENT';
export const CREATE_REVIEW_ADD_PRIMARY_USER = 'CREATE_REVIEW_ADD_PRIMARY_USER';
export const CREATE_REVIEW_UPDATE_TEAM = 'CREATE_REVIEW_UPDATE_TEAM';
export const CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION =
  'CREATE_REVIEW_UPDATE_SHADOWREVIEWER_LOCATION';
export const CREATE_REVIEW_REMOVE_PRIMARY_USER =
  'CREATE_REVIEW_REMOVE_PRIMARY_USER';
export const UPDATE_TEAM_POSITIONS = 'UPDATE_TEAM_POSITIONS';
export const JOURNAL_LOGS_FETCHED = 'JOURNAL_LOGS_FETCHED';
export const REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED =
  'REVIEW_FOLLOWUP_FINDING_DETAILS_FETCHED';
export const REVIEW_FOLLOWUP_FUR_TEAM_FETCHED =
  'REVIEW_FOLLOWUP_FUR_TEAM_FETCHED';
export const REVIEW_FOLLOWUP_FUR_TEAM_UPDATED =
  'REVIEW_FOLLOWUP_FUR_TEAM_UPDATED';
export const EXPLORATION_SUMMARY_FETCHED = 'EXPLORATION_SUMMARY_FETCHED';
export const MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED =
  'MANAGEMENT_SYSTEM_ANALYSIS_REPORT_FETCHED';
export const PRE_REVIEW_SUMMARY_PAGE_FETCHED =
  'PRE_REVIEW_SUMMARY_PAGE_FETCHED';

// Survey
export const SURVEYS_FETCHED = 'SURVEYS_FETCHED';
export const SURVEY_SUBMISSION_FETCHED = 'SURVEY_SUBMISSION_FETCHED';
export const SURVEY_SUBMISSION_UPDATED = 'SURVEY_SUBMISSION_UPDATED';
export const SURVEY_SUBMISSION_SAVED = 'SURVEY_SUBMISSION_SAVED';
export const SURVEY_ALL_SUBMISSION_FETCHED = 'SURVEY_ALL_SUBMISSION_FETCHED';
export const SURVEY_DETAILS_FETCHED = 'SURVEY_DETAILS_FETCHED';
export const SURVEYS_FINALIZED = 'SURVEYS_FINALIZED';
export const SURVEY_OUTCOMES_FETCHED = 'SURVEY_OUTCOMES_FETCHED';
export const SURVEY_OUTCOMES_SET = 'SURVEY_OUTCOMES_SET';
export const DATA_COLLECTION_FETCHED = 'DATA_COLLECTION_FETCHED';
export const CLASS_ROOM_OUTCOMES_FETCHED = 'CLASS_ROOM_OUTCOMES_FETCHED';
export const CENTER_OUTCOMES_FETCHED = 'CENTER_OUTCOMES_FETCHED';
export const RAS_URL = 'RAS_URL';
export const SURVEY_CHILD_FILES_SUMMARY_FETCHED =
  'SURVEY_CHILD_FILES_SUMMARY_FETCHED';
export const SURVEY_SUBMISSION_DELETED = 'SURVEY_SUBMISSION_DELETED';
export const SURVEY_ATTACHMENT_DOWNLOADING = 'SURVEY_ATTACHMENT_DOWNLOADING';
export const SURVEY_ATTACHMENT_DOWNLOADED = 'SURVEY_ATTACHMENT_DOWNLOADED';
export const SURVEY_COMMENTS_FETCHED = 'SURVEY_COMMENTS_FETCHED';
export const CHILD_FILES_ERSEA_SUMMARY_FETCHED =
  'CHILD_FILES_ERSEA_SUMMARY_FETCHED';
export const CONSOLIDATED_QUESTIONS_FETCHED = 'CONSOLIDATED_QUESTIONS_FETCHED';
export const CONSOLIDATED_ANSWERS_FETCHED = 'CONSOLIDATED_ANSWERS_FETCHED';
export const DATA_COLLECTION_LOCKED = 'DATA_COLLECTION_LOCKED';
export const DATA_COLLECTION_DETAILS_FETCHED =
  'DATA_COLLECTION_DETAILS_FETCHED';
export const MULTI_SURVEY_FORM_FETCHED = 'MULTI_SURVEY_FORM_FETCHED';
export const ADDITIONAL_CITATION_FORM_FETCHED =
  'ADDITIONAL_CITATION_FORM_FETCHED';
export const CONSOLIDATED_ANSWERS_CLEARED = 'CONSOLIDATED_ANSWERS_CLEARED';
export const SURVEY_FOTP_FINDINGS_FETCHED = 'SURVEY_FOTP_FINDINGS_FETCHED';
// Notes
export const NOTES_FETCHED = 'NOTES_FETCHED';
export const USER_PROFILE_UPDATED_NOTES_FETCHED =
  'USER_PROFILE_UPDATED_NOTES_FETCHED';

// FA-1
export const FA1_FORM_DETAIL_FETCHED = 'FA1_FORM_DETAIL_FETCHED';
export const FA1_FORM_SCHEMA_LOADING = 'FA1_FORM_SCHEMA_LOADING';
export const FA1_FORM_SCHEMA_FETCHED = 'FA1_FORM_SCHEMA_FETCHED';
export const FA1_GUIDE_SELECTED = 'FA1_GUIDE_SELECTED';
export const FA1_PERFORMANCE_MEASURE_SELECTED =
  'FA1_PERFORMANCE_MEASURE_SELECTED';
export const FA1_CENTER_SELECTED = 'FA1_CENTER_SELECTED';
export const FA1_CLASSROOM_SELECTED = 'FA1_CLASSROOM_SELECTED';
export const FA1_SUBMISSION_FETCHED = 'FA1_SUBMISSION_FETCHED';
export const FA1_DRAFT_SAVED = 'FA1_DRAFT_SAVED';
export const FA1_SUBMISSION_SAVED = 'FA1_SUBMISSION_SAVED';
export const FA1_SUBMISSION_UPDATED = 'FA1_SUBMISSION_UPDATED';
export const FA1_FORM_FETCHING = 'FA1_FORM_FETCHING';
export const FA1_FORM_FETCHED = 'FA1_FORM_FETCHED';
export const FA1_FORM_SUBMITTING = 'FA1_FORM_SUBMITTING';
export const FA1_NEW_DATA_ADDED = 'FA1_NEW_DATA_ADDED';
export const FA1_DRAFT_DATA_ADDED = 'FA1_DRAFT_DATA_ADDED';
export const FA1_DATA_UPDATED = 'FA1_DATA_UPDATED';
export const FA1_UPDATE_GUIDE = 'FA1_UPDATE_GUIDE';
export const FA1_CLEAR_FORMS = 'FA1_CLEAR_FORMS';

// Pre-site
export const PRESITE_PERFORMANCE_MEASURE_SELECTED =
  'PRESITE_PERFORMANCE_MEASURE_SELECTED';
export const PRESITE_FORM_DETAIL_FETCHED = 'PRESITE_FORM_DETAIL_FETCHED';
export const PRESITE_FORM_SCHEMA_LOADING = 'PRESITE_FORM_SCHEMA_LOADING';
export const PRESITE_FORM_SCHEMA_FETCHED = 'PRESITE_FORM_SCHEMA_FETCHED';
export const PRESITE_SUBMISSION_FETCHED = 'PRESITE_SUBMISSION_FETCHED';
export const PRESITE_SUBMISSION_CLEARED = 'PRESITE_SUBMISSION_CLEARED';
export const PRESITE_DRAFT_SAVED = 'PRESITE_DRAFT_SAVED';
export const PRESITE_SUBMISSION_SAVED = 'PRESITE_SUBMISSION_SAVED';
export const PRESITE_SUBMISSION_UPDATED = 'PRESITE_SUBMISSION_UPDATED';
export const PRESITE_UPDATE_GUIDE = 'PRESITE_UPDATE_GUIDE';

// FA-2
export const FA2_GUIDES_FETCHED = 'FA2_GUIDES_FETCHED';
export const FA2_GUIDE_SELECTED = 'FA2_GUIDE_SELECTED';
export const FA2_PERFORMANCE_MEASURE_SELECTED =
  'FA2_PERFORMANCE_MEASURE_SELECTED';
export const FA2_FORM_FETCHING = 'FA2_FORM_FETCHING';
export const FA2_FORM_FETCHED = 'FA2_FORM_FETCHED';
export const FA2_FORM_SUBMITTING = 'FA2_FORM_SUBMITTING';
export const FA2_NEW_DATA_ADDED = 'FA2_NEW_DATA_ADDED';
export const FA2_DRAFT_DATA_ADDED = 'FA2_DRAFT_DATA_ADDED';
export const FA2_DATA_UPDATED = 'FA2_DATA_UPDATED';
export const FA2_CENTER_SELECTED = 'FA2_CENTER_SELECTED';
export const FA2_CLASSROOM_SELECTED = 'FA2_CLASSROOM_SELECTED';
export const FA2_FORM_DETAIL_FETCHED = 'FA2_FORM_DETAIL_FETCHED';
export const FA2_FORM_SCHEMA_LOADING = 'FA2_FORM_SCHEMA_LOADING';
export const FA2_FORM_SCHEMA_FETCHED = 'FA2_FORM_SCHEMA_FETCHED';
export const FA2_SUBMISSION_FETCHED = 'FA2_SUBMISSION_FETCHED';
export const FA2_DRAFT_SAVED = 'FA2_DRAFT_SAVED';
export const FA2_SUBMISSION_SAVED = 'FA2_SUBMISSION_SAVED';
export const FA2_SUBMISSION_UPDATED = 'FA2_SUBMISSION_UPDATED';
export const FA2_UPDATE_GUIDE = 'FA2_UPDATE_GUIDE';
export const FA2_CENTERS_CLASSROOMS_UPDATED = 'FA2_CENTERS_CLASSROOMS_UPDATED';
export const CENTER_CLASSROOM_FINDINGS_FETCHED =
  'CENTER_CLASSROOM_FINDINGS_FETCHED';
export const FA2_CLEAR_FORMS = 'FA2_CLEAR_FORMS';

// RAN
export const RAN_FORM_DETAIL_FETCHED = 'RAN_FORM_DETAIL_FETCHED';
export const RAN_FORM_SCHEMA_LOADING = 'RAN_FORM_SCHEMA_LOADING';
export const RAN_FORM_SCHEMA_FETCHED = 'RAN_FORM_SCHEMA_FETCHED';
export const RAN_GUIDE_SELECTED = 'RAN_GUIDE_SELECTED';
export const RAN_PERFORMANCE_MEASURE_SELECTED =
  'RAN_PERFORMANCE_MEASURE_SELECTED';
export const RAN_CENTER_SELECTED = 'RAN_CENTER_SELECTED';
export const RAN_CLASSROOM_SELECTED = 'RAN_CLASSROOM_SELECTED';
export const RAN_SUBMISSION_FETCHED = 'RAN_SUBMISSION_FETCHED';
export const RAN_DRAFT_SAVED = 'RAN_DRAFT_SAVED';
export const RAN_SUBMISSION_SAVED = 'RAN_SUBMISSION_SAVED';
export const RAN_SUBMISSION_UPDATED = 'RAN_SUBMISSION_UPDATED';
export const RAN_FORM_FETCHING = 'RAN_FORM_FETCHING';
export const RAN_FORM_FETCHED = 'RAN_FORM_FETCHED';
export const RAN_FORM_SUBMITTING = 'RAN_FORM_SUBMITTING';
export const RAN_NEW_DATA_ADDED = 'RAN_NEW_DATA_ADDED';
export const RAN_DRAFT_DATA_ADDED = 'RAN_DRAFT_DATA_ADDED';
export const RAN_DATA_UPDATED = 'RAN_DATA_UPDATED';
export const RAN_UPDATE_GUIDE = 'RAN_UPDATE_GUIDE';
export const RAN_CLEAR_FORMS = 'RAN_CLEAR_FORMS';
export const RAN_INCIDENTS_SUBMISSION_SAVED = 'RAN_INCIDENTS_SUBMISSION_SAVED';

// Special
export const SPECIAL_ASSIGNMENTS_ADDED = 'SPECIAL_ASSIGNMENTS_ADDED';
export const SPECIAL_ASSIGNMENTS_FETCHED = 'SPECIAL_ASSIGNMENTS_FETCHED';
export const SPECIAL_ASSIGNMENTS_LOADING = 'SPECIAL_ASSIGNMENTS_LOADING';
export const SPECIAL_ASSIGNMENTS_UPDATED = 'SPECIAL_ASSIGNMENTS_UPDATED';

export const SPECIAL_SURVEY_ASSIGNMENTS_FETCHED =
  'SPECIAL_SURVEY_ASSIGNMENTS_FETCHED';
export const SPECIAL_SURVEY_ASSIGNMENTS_UPDATED =
  'SPECIAL_SURVEY_ASSIGNMENTS_UPDATED';

// Grantee
export const GRANTEES_FETCHED = 'GRANTEES_FETCHED';
export const GRANTEE_FETCHED = 'GRANTEE_FETCHED';
export const GRANTEE_OPTIONS_FETCHED = 'GRANTEE_OPTIONS_FETCHED';
export const GRANTEE_SELECTED = 'GRANTEE_SELECTED';
export const ANOTHER = 'ANOTHER';
export const GRANTEE_DETAIL_FETCHED = 'GRANTEE_DETAIL_FETCHED';
export const GRANTEES_FILTERED = 'GRANTEES_FILTERED';
export const GRANTEE_FILTER_CLEARED = 'GRANTEE_FILTER_CLEARED';
export const AGENCY_LOOKUP = 'AGENCY_LOOKUP';
export const GRANTEE_HASH_FETCHED = 'GRANTEE_HASH_FETCHED';
export const GRANTEE_STATUS_FETCHED = 'GRANTEE_STATUS_FETCHED';
export const GRANTEE_UNAVAILABILTY_UPDATED = 'GRANTEE_UNAVAILABILTY_UPDATED';
export const GRANTEE_TO_EXPORT = 'GRANTEE_TO_EXPORT';
export const GRANTEE_INVITED = 'GRANTEE_INVITED';
export const RESET_GRANTEES_INVITED = 'RESET_GRANTEES_INVITED';
export const GRANTEE_SCHEDULE_UPDATED = 'GRANTEE_SCHEDULE_UPDATED';
export const GRANTEE_EXTENSION_FETCHED = 'GRANTEE_EXTENSION_FETCHED';
export const GRANTEE_EXTENSION_HISTORY_FETCHED =
  'GRANTEE_EXTENSION_HISTORY_FETCHED';
export const GRANTEE_SCHEDULE_RESULTS = 'GRANTEE_SCHEDULE_RESULTS';

// Profile
export const SET_PROFILE = 'SET_PROFILE';
export const USER_ROLES_FETCHED = 'USER_ROLES_FETCHED';
export const SET_PROFILE_ERROR = 'SET_PROFILE_ERROR';
export const USER_PROFILE_TAB_SELECTED = 'USER_PROFILE_TAB_SELECTED';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_CARD_FETCHED = 'USER_CARD_FETCHED';
export const USER_PROFILE_FETCHED_ERROR = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED_FAIL = 'USER_PROFILE_UPDATED_FAIL';
export const USER_ROLES_UPDATED = 'USER_ROLES_UPDATED';
export const USERS_FILTERED = 'USERS_FILTERED';
export const USERS_FILTER_CLEARED = 'USERS_FILTER_CLEARED';
export const USER_HISTORY_FETCHED = 'USER_HISTORY_FETCHED';

// Error
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// Report
export const REPORT_LIST_FETCHED = 'REPORT_LIST_FETCHED';
export const REPORT_UPDATED = 'REPORT_UPDATED';
export const REPORT_EXTERNAL_USERS_FETCHED = 'REPORT_EXTERNAL_USERS_FETCHED';
export const REPORT_VERSION_DATA_FETCHED = 'REPORT_VERSION_DATA_FETCHED';
export const REPORT_STATUS_DATA_FETCHED = 'REPORT_STATUS_DATA_FETCHED';
export const REPORT_SHARED = 'REPORT_SHARED';
export const REPORT_SHARED_PDF = 'REPORT_SHARED_PDF';
export const REPORT_SAVED = 'REPORT_SAVED';
export const REPORT_TEMPLATE = 'REPORT_TEMPLATE';
export const REPORT_DRAFT_TEMPLATE = 'REPORT_DRAFT_TEMPLATE';
export const REPORT_FINDINGS = 'REPORT_FINDINGS';
export const REPORT_GRANTEE_CONTACT = 'REPORT_GRANTEE_CONTACT';
export const REPORT_STAGE_UPDATED = 'REPORT_STAGE_UPDATED';
export const REPORT_UPLOADED_COMMENT_FILES = 'REPORT_UPLOADED_COMMENT_FILES';
export const REPORT_COMMENT_FILE_NAMES = 'REPORT_COMMENT_FILE_NAMES';
export const REPORT_COMMENT_FILE_CONTENTS = 'REPORT_COMMENT_FILE_CONTENTS';
export const REPORT_COMMENT_FILE_LIST = 'REPORT_COMMENT_FILE_LIST';
export const REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS =
  'REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS';
export const REPORT_DEADLINE_DATES = 'REPORT_DEADLINE_DATES';
export const REPORT_SEARCH_PATTERNS = 'REPORT_SEARCH_PATTERNS';
export const REPORT_ASSIGNEES_FILTER = 'REPORT_ASSIGNEES_FILTER';
export const SET_REPORT_CONTENT = 'SET_REPORT_CONTENT';
export const SEND_REPORT_COMMENT = 'SEND_REPORT_COMMENT';
export const FINALIZED_REPORT_DOWNLOADED = 'FINALIZED_REPORT_DOWNLOADED';
export const REPORT_NEW_FETCHED = 'REPORT_NEW_FETCHED';

// Weather
export const WEATHER_FETCHING = 'WEATHER_FETCHING';
export const WEATHER_FETCHED = 'WEATHER_FETCHED';

// Geocode
export const GEOCODE_FETCHED = 'GEOCODE_FETCHED';
export const GEOCODE_CLEARED = 'GEOCODE_CLEARED';
export const GEOCODE_LIST_FETCHED = 'GEOCODE_LIST_FETCHED';

// Users
export const USERS_FETCHED = 'USERS_FETCHED';
export const REPORT_COMMENT_USERS = 'REPORT_COMMENT_USERS';
export const USERS_INTERNAL_FETCHED = 'USERS_INTERNAL_FETCHED';
export const USERS_INTERNAL_TEAM_FETCHED = 'USERS_INTERNAL_TEAM_FETCHED';

export const REGIONAL_USERS_FETCHED = 'REGIONAL_USERS_FETCHED';
export const FOM_USERS_FETCHED = 'FOM_USERS_FETCHED';

// Lookups
export const LOOKUPS_FETCHED = 'LOOKUPS_FETCHED';
export const CATEGORY_LOOKUPS_FETCHED = 'CATEGORY_LOOKUPS_FETCHED';
export const LOOKUP_CATEGORIES_FETCHED = 'LOOKUP_CATEGORIES_FETCHED';
export const LOOKUP_ADDED = 'LOOKUP_ADDED';
export const LOOKUP_UPADATED = 'LOOKUP_UPADATED';
export const AMS_LOOKUP_FETCHED = 'AMS_LOOKUP_FETCHED';
export const SURVEY_LOOKUPS_FETCHED = 'SURVEY_LOOKUPS_FETCHED';
export const CONTENT_AREA_LOOKUPS_FETCHED = 'CONTENT_AREA_LOOKUPS_FETCHED';
export const PERFORMANCE_AREA_LOOKUPS_FETCHED =
  'PERFORMANCE_AREA_LOOKUPS_FETCHED';
export const PERFORMANCE_MEASURE_LOOKUPS_FETCHED =
  'PERFORMANCE_MEASURE_LOOKUPS_FETCHED';

// Message
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// File
export const FILE_FETCHED = 'FILE_FETCHED';

// Admin
export const ADMIN_TAB_SELECTED = 'ADMIN_TAB_SELECTED';
export const AZURE_USER_CREATED = 'AZURE_USER_CREATED';
export const USER_PROFILE_CREATED = 'USER_PROFILE_CREATED';
export const USER_PROFILES_FETCHED = 'USER_PROFILES_FETCHED';
export const ROLES_LIST_FETCHED = 'ROLES_LIST_FETCHED';
export const ADMIN_FILES_FETCHED = 'ADMIN_FILES_FETCHED';
export const SEARCH_USERS = 'SEARCH_USERS';
export const ADMIN_LOGS_FETCHED = 'ADMIN_LOGS_FETCHED';

// Calendar
export const CALENDAR_EVENTS_FETCHED = 'CALENDAR_EVENTS_FETCHED';
export const CALENDAR_EVENT_ADDED = 'CALENDAR_EVENT_ADDED';
export const CALENDAR_HOLIDAYS_FETCHED = 'CALENDAR_HOLIDAYS_FETCHED';
export const CALENDAR_EVENT_DELETED = 'CALENDAR_EVENT_DELETED';
export const CALENDAR_EVENT_UPDATED = 'CALENDAR_EVENT_UPDATED';

// Workflow
export const WORKFLOW_STATUS_FETCHED = 'WORKFLOW_STATUS_FETCHED';

// Submissions Types
export const SUBMISSIONS_FETCHING = 'SUBMISSIONS_FETCHING';
export const SUBMISSIONS_FETCHED = 'SUBMISSIONS_FETCHED';
export const SUBMISSION_FETCHED = 'SUBMISSION_FETCHED';
export const SUBMISSION_UPLOAD = 'SUBMISSION_UPLOAD';
export const SUBMISSION_SELECTED = 'SUBMISSION_SELECTED';
export const SUBMISSION_FU_SELECTED = 'SUBMISSION_FU_SELECTED';
export const CLASS_ASSIGNMENTS_FETCHED = 'CLASS_ASSIGNMENTS_FETCHED';
export const CLASS_FORM_FETCHED = 'CLASS_FORM_FETCHED';
export const CLASS_TASK_DETAIL_FETCHED = 'CLASS_TASK_DETAIL_FETCHED';
export const CLASS_ASSIGNMENTS_ADDED = 'CLASS_ASSIGNMENTS_ADDED';
export const CLASS_SUBMISSIONS_DETAIL_FETCHED =
  'CLASS_SUBMISSIONS_DETAIL_FETCHED';
export const CLASS_SAMPLE_REPORT_FETCHED = 'CLASS_SAMPLE_REPORT_FETCHED';
export const DUAL_COMPARISON_REPORT_FETCHED = 'DUAL_COMPARISON_REPORT_FETCHED';
export const CLASS_METHODOLOGY_FETCHED = 'CLASS_METHODOLOGY_FETCHED';
export const PASS_FAIL_REPORT_FETCHED = 'PASS_FAIL_REPORT_FETCHED';
export const FOLLOWUP_TASK_DETAIL_FETCHED = 'FOLLOWUP_TASK_DETAIL_FETCHED';
export const CLASS_VERSION_HISTORY_FETCHED = 'CLASS_VERSION_HISTORY_FETCHED';
export const FU_TASK_DETAIL_FETCHED = 'FU_TASK_DETAIL_FETCHED';
export const CLASS_FREEZE_STATUS_FETCHED = 'CLASS_FREEZE_STATUS_FETCHED';

// Invitation
export const INVITATION_LOADED = 'INVITATION_LOADED';
export const INVITATION_ACCEPTED = 'INVITATION_ACCEPTED';
export const INVITATION_DECLINED = 'INVITATION_DECLINED';

// FORMS
export const FORM_SCHEMA_FETCHED = 'FORM_SCHEMA_FETCHED';
export const FORM_DATA_SUBMITTED = 'FORM_DATA_SUBMITTED';

// FORM BUILDER
export const FOCUS_COMPONENT = 'FOCUS_COMPONENT';

// Sliding Panel
export const SLIDER_OPEN = 'SLIDER_OPEN';

// Agreements
export const GET_AGREEMENT = 'GET_AGREEMENT';
export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';
export const GET_CURRENT_USER_AGREEMENTS = 'GET_CURRENT_USER_AGREEMENTS';
